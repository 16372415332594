import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';
import { getDecryptRayaAppsData } from 'helpers/getQueryStringUrl';
import { RAYA_APPS_DATA } from 'common/constants';
import { getCookie } from 'helpers/saveToCookies';

/**
 * decrypt raya apps data from cookie for use to initial state
 */
const decryptDecodedDataRayaApps = getDecryptRayaAppsData(
  getCookie(RAYA_APPS_DATA)
);

const { nikFlexi, nikRaya } = decryptDecodedDataRayaApps
  ? decryptDecodedDataRayaApps
  : {};

const initialState = {
  attemptSubmissionKTP: 1,
  loading: false,
  loanProductOptions: '',
  loanPurposeOptions: '',
  loanAmount: 0,
  loanFee: '',
  tenure: 0,
  loanPurpose: '',
  interestRate: '',
  schemeCode: '',
  subCodeLoanScheme: [],
  shouldManualCheck: false,
  productCode: '',
  productCategory: '',
  productSubCategory: '',
  monthlyInstallment: '',
  bankCodes: '',
  eKTP: localStorage.getItem('documentDetails')
    ? JSON.parse(localStorage.getItem('documentDetails')).docKey
    : nikFlexi || '',
  eKTPRaya: nikRaya || '',
  bankCode: localStorage.getItem('bankCode')
    ? JSON.parse(localStorage.getItem('bankCode'))
    : '',
  cardNumber: '',
  cardExpDate: '',
  camscall: localStorage.getItem('camscall')
    ? JSON.parse(localStorage.getItem('camscall'))
    : '',
  silverLakeAcct: localStorage.getItem('acct')
    ? JSON.parse(localStorage.getItem('acct'))
    : '',
  silverLakeCIF: localStorage.getItem('cif')
    ? JSON.parse(localStorage.getItem('cif'))
    : '',
  atmInqRes: '',
  customerName: '',
  appInqRes: '',
  approvalDetails: localStorage.getItem('approvalDetails')
    ? JSON.parse(localStorage.getItem('approvalDetails'))
    : '',
  inCompleteAppDetails: localStorage.getItem('inCompleteAppDetails')
    ? JSON.parse(localStorage.getItem('inCompleteAppDetails'))
    : '',
  applicationStatus: localStorage.getItem('appStatus')
    ? localStorage.getItem('appStatus')
    : '',
  accountRayaInformation: localStorage.getItem('accountRayaInformation')
    ? JSON.parse(localStorage.getItem('accountRayaInformation'))
    : '',
  applicationId: '',
  nextDate: '',
  applicationData: '',
  educationOptions: '',
  fieldOfWorkOptions: '',
  creditCardOptions: '',
  companyAgencyOptions: '',
  provinceOptions: '',
  cityOptions: '',
  subDistrictOptions: '',
  villageOptions: '',
  residenceOptions: '',
  typeOfWorkOptions: '',
  fieldOfBusinessOptions: '',
  fieldOfSubBusinessOptions: '',
  employeeOptions: '',
  purposeOfOpeningAccountOptions: '',
  sourceOfIncomeOptions: '',
  companyOptions: '',
  dependentsOptions: '',
  marriageOptions: '',
  relativesOptions: '',
  applicationDetails: localStorage.getItem('applicationDetails')
    ? JSON.parse(localStorage.getItem('applicationDetails'))
    : '',
  documentDetails: localStorage.getItem('documentDetails')
    ? JSON.parse(localStorage.getItem('documentDetails'))
    : '',
  respPayrollDetails: localStorage.getItem('respPayrollDetails')
    ? JSON.parse(localStorage.getItem('respPayrollDetails'))
    : '',
  ektpImg: '',
  npwpImg: '',
  personInfo: localStorage.getItem('personInfo')
    ? JSON.parse(localStorage.getItem('personInfo'))
    : {},
  occupationInfo: localStorage.getItem('occupationInfo')
    ? JSON.parse(localStorage.getItem('occupationInfo'))
    : '',
  familyInfo: localStorage.getItem('familyInfo')
    ? JSON.parse(localStorage.getItem('familyInfo'))
    : '',
  creditScoreRes: '',
  whitelistAddress: '',
  isSecondApply: '',
  companyAddress: '',
  companyName: '',
  loanSchemes: localStorage.getItem('loanSchemes')
    ? JSON.parse(localStorage.getItem('loanSchemes'))
    : '',
  loanScheme: localStorage.getItem('loanScheme')
    ? JSON.parse(localStorage.getItem('loanScheme'))
    : '',
  verifyPayrollData: localStorage.getItem('verifyPayrollData')
    ? JSON.parse(localStorage.getItem('verifyPayrollData'))
    : '',
  npwp: '',
  stayPeriod: 0,
  alternativeAmount: '',
  alternativeTenure: '',
  custCategory: [],
  disburseLifetime: localStorage.getItem('disburseLifetime')
    ? Number(localStorage.getItem('disburseLifetime'))
    : 0,
  rayanetInquiryReferenceVO: localStorage.getItem('rayanetInquiryReferenceVO')
    ? JSON.parse(localStorage.getItem('rayanetInquiryReferenceVO'))
    : '',
  jobTitleOptions: '',
  dailyNormalTransactionsOptions: '',
  accountInformationInq: localStorage.getItem('accountInformationInq')
    ? JSON.parse(localStorage.getItem('accountInformationInq'))
    : {},
  accountRayaSavingExist: '',
  acctCreatedFromSaving: '',
  isExistWhitelist: '',
  ktpSubmitFormRes: '',
  agreeCheckboxKtp:
    localStorage.getItem('checkboxAgreeKtp') === 'true' ? true : false,
  whitelistReferenceVO: localStorage.getItem('whitelistReferenceVO')
    ? JSON.parse(localStorage.getItem('whitelistReferenceVO'))
    : '',
  respSubmitKtp: localStorage.getItem('respSubmitKtp')
    ? JSON.parse(localStorage.getItem('respSubmitKtp'))
    : {},
  kasbonInquiryReferenceVO: localStorage.getItem('kasbonInquiryReferenceVO')
    ? JSON.parse(localStorage.getItem('kasbonInquiryReferenceVO'))
    : '',
  isCompanyUpdated: '',
  blockScreen: '',
  flagBoxs: '',
  bottomSheet: '',
  isMaintenance: false,
  isSameContactOther: false,
  responseCodeReject: '',
  docRiplay: '',
  isCustomerConsent: '',
  isWordingPmi: '',
  freezeDateSuccess: localStorage.getItem('freezeDateSuccess')
    ? Number(localStorage.getItem('freezeDateSuccess'))
    : 0
};

const loading = (state, action) => {
  return updateObject(state, { loading: action.loading });
};

const setApplicationId = (state, action) => {
  return updateObject(state, { applicationId: action.id });
};

const setApplicationStatus = (state, action) => {
  localStorage.setItem('appStatus', action.status);
  return updateObject(state, { applicationStatus: action.status });
};

const setDisburseLifetime = (state, action) => {
  localStorage.setItem('disburseLifetime', action.disburseLifetime * 60);
  return updateObject(state, {
    disburseLifetime: action.disburseLifetime * 60
  });
};

const setSubtractionDisburseLifeTime = state => {
  let valueLifeTime;

  if (state.disburseLifetime <= 0) valueLifeTime = 0;
  else valueLifeTime = state.disburseLifetime - 1;

  localStorage.setItem('disburseLifetime', valueLifeTime);
  return updateObject(state, {
    disburseLifetime: valueLifeTime
  });
};

const setNextDate = (state, action) => {
  return updateObject(state, { nextDate: action.nextDate, loading: false });
};

const setResponseCodeReject = (state, action) => {
  return updateObject(state, { responseCodeReject: action.responseCodeReject });
};

const setAppInqRes = (state, action) => {
  const res = action.res;
  localStorage.setItem('appStatus', res.applicationStatus.cmCode);
  return updateObject(state, {
    appInqRes: res,
    applicationStatus: res ? res.applicationStatus.cmCode : 'APP_EXPIRED'
  });
};

const setApprovalDetails = (state, action) => {
  localStorage.setItem('approvalDetails', JSON.stringify(action.data));
  return updateObject(state, {
    approvalDetails: action.data
  });
};

const setIncompleteAppDetails = (state, action) => {
  localStorage.setItem('inCompleteAppDetails', JSON.stringify(action.data));
  return updateObject(state, {
    inCompleteAppDetails: action.data
  });
};

const setAppData = (state, action) => {
  return updateObject(state, { applicationData: action.res, loading: false });
};

const setLoanProductOptions = (state, action) => {
  return updateObject(state, { loanProductOptions: action.product });
};

const setCustCategory = (state, action) => {
  return updateObject(state, { custCategory: action.custCategory });
};

const setLoanPurposeOptions = (state, action) => {
  return updateObject(state, {
    loanPurposeOptions: action.options,
    loading: false
  });
};

const setLoanAmount = (state, action) => {
  return updateObject(state, { loanAmount: action.amount });
};

const setTenure = (state, action) => {
  return updateObject(state, { tenure: action.tenure });
};

const setLoanPurpose = (state, action) => {
  return updateObject(state, { loanPurpose: action.purpose });
};

const setProductConfig = (state, action) => {
  return updateObject(state, {
    interestRate: action.rate,
    productCode: action.code,
    productCategory: action.category,
    productSubCategory: action.subCategory,
    schemeCode: action.schemeCode,
    monthlyInstallment: action.installment
  });
};

const setBankOptions = (state, action) => {
  return updateObject(state, { bankCodes: action.bankCodes, loading: false });
};

const setApplicationDetails = (state, action) => {
  localStorage.setItem('applicationDetails', JSON.stringify(action.data));
  return updateObject(state, { applicationDetails: action.data });
};

const setDocumentDetails = (state, action) => {
  return updateObject(state, { documentDetails: action.data });
};

const setRespPayrollDetails = (state, action) => {
  localStorage.setItem('respPayrollDetails', JSON.stringify(action.data));
  return updateObject(state, { respPayrollDetails: action.data });
};

const setEktp = (state, action) => {
  return updateObject(state, { eKTP: action.ektp });
};

const setEktpRaya = (state, action) => {
  return updateObject(state, { eKTPRaya: action.eKTPRaya });
};

const setBankCode = (state, action) => {
  if (action.source)
    localStorage.setItem('bankCode', JSON.stringify(action.source));
  return updateObject(state, { bankCode: action.source });
};

const setCardNumber = (state, action) => {
  return updateObject(state, { cardNumber: action.cardNumber });
};

const setCardExpDate = (state, action) => {
  return updateObject(state, { cardExpDate: action.expDate });
};

const setAtmInqRes = (state, action) => {
  localStorage.setItem(
    'camscall',
    JSON.stringify(action.response[0].camscallReferenceVO)
  );
  localStorage.setItem(
    'acct',
    JSON.stringify(action.response[0].silverLakeAcctInquiryReferenceVO)
  );
  localStorage.setItem(
    'cif',
    JSON.stringify(action.response[0].silverLakeCIFInquiryReferenceVO)
  );
  return updateObject(state, {
    atmInqRes: action.response,
    loading: false,
    camscall: action.response[0].camscallReferenceVO,
    silverLakeAcct: action.response[0].silverLakeAcctInquiryReferenceVO,
    silverLakeCIF: action.response[0].silverLakeCIFInquiryReferenceVO
  });
};

const setCustomerName = (state, action) => {
  return updateObject(state, { customerName: action.name });
};

const setEducationOptions = (state, action) => {
  return updateObject(state, {
    educationOptions: action.options,
    loading: false
  });
};
const setFieldOfWorkOptions = (state, action) => {
  return updateObject(state, {
    fieldOfWorkOptions: action.options,
    loading: false
  });
};

const setCreditCardOptions = (state, action) => {
  return updateObject(state, {
    creditCardOptions: action.options,
    loading: false
  });
};

const setProvinceOptions = (state, action) => {
  return updateObject(state, {
    provinceOptions: action.options,
    loading: false
  });
};

const setCityOptions = (state, action) => {
  return updateObject(state, { cityOptions: action.options, loading: false });
};

const setSubDistrictOptions = (state, action) => {
  return updateObject(state, {
    subDistrictOptions: action.options,
    loading: false
  });
};

const setVillageOptions = (state, action) => {
  return updateObject(state, {
    villageOptions: action.options,
    loading: false
  });
};

const setCompanyAgencyOptions = (state, action) => {
  return updateObject(state, {
    companyAgencyOptions: action.options,
    loading: false
  });
};

const setJobTitleOptions = (state, action) => {
  return updateObject(state, {
    jobTitle: action.options,
    loading: false
  });
};

const setDailyNormalTransactionsOptions = (state, action) => {
  return updateObject(state, {
    dailyNormalTransactionsOptions: action.options,
    loading: false
  });
};

const setResidenceOptions = (state, action) => {
  return updateObject(state, {
    residenceOptions: action.options,
    loading: false
  });
};

const setTypeOfWorkOptions = (state, action) => {
  return updateObject(state, {
    typeOfWorkOptions: action.options,
    loading: false
  });
};

const setFieldOfBusinessOptions = (state, action) => {
  return updateObject(state, {
    fieldOfBusinessOptions: action.options,
    loading: false
  });
};

const setFieldOfSubBusinessOptions = (state, action) => {
  return updateObject(state, {
    fieldOfSubBusinessOptions: action.options,
    loading: false
  });
};

const setEmployeeOptions = (state, action) => {
  return updateObject(state, {
    employeeOptions: action.options,
    loading: false
  });
};

const setPurposeOfOpeningAccountOptions = (state, action) => {
  return updateObject(state, {
    purposeOfOpeningAccountOptions: action.options,
    loading: false
  });
};

const setSourceOfIncomeOptions = (state, action) => {
  return updateObject(state, {
    sourceOfIncomeOptions: action.options,
    loading: false
  });
};

const setCompanyOptions = (state, action) => {
  return updateObject(state, {
    companyOptions: action.options,
    loading: false
  });
};

const setDependentsOptions = (state, action) => {
  return updateObject(state, {
    dependentsOptions: action.options,
    loading: false
  });
};

const setMariageOptions = (state, action) => {
  return updateObject(state, {
    marriageOptions: action.options,
    loading: false
  });
};

const setRelativesOptions = (state, action) => {
  return updateObject(state, {
    relativesOptions: action.options,
    loading: false
  });
};

const setEktpImg = (state, action) => {
  return updateObject(state, { ektpImg: action.value });
};

const setPersonInfo = (state, action) => {
  return updateObject(state, { personInfo: action.personInfo });
};

const setKtpAddressDetails = (state, action) => {
  return updateObject(state, { ktpAddressDetails: action.data });
};

const setOccupationInfo = (state, action) => {
  return updateObject(state, { occupationInfo: action.occupationInfo });
};

const setFamilyInfo = (state, action) => {
  return updateObject(state, { familyInfo: action.familyInfo });
};

const setCreditScoreRes = (state, action) => {
  return updateObject(state, { creditScoreRes: action.res, loading: false });
};

const setWhitelistAddress = (state, action) => {
  return updateObject(state, { whitelistAddress: action.whitelistAddress });
};

const setSecondApply = (state, action) => {
  return updateObject(state, { isSecondApply: action.set });
};

const setCompanyName = (state, action) => {
  return updateObject(state, { companyName: action.data });
};

const setCompanyAddress = (state, action) => {
  return updateObject(state, { companyAddress: action.data });
};

const setAccountNumber = (state, action) => {
  return updateObject(state, { accountNumber: action.accountNumber });
};

const setDehydrateData = state => {
  return updateObject(state, {
    eKTP: '',
    bankCode: '',
    cardNumber: '',
    cardExpDate: '',
    ektpImg: '',
    personInfo: {},
    occupationInfo: '',
    familyInfo: ''
  });
};

const setSubCodeLoanScheme = (state, action) => {
  return updateObject(state, { subCodeLoanScheme: action.payload });
};

const setLoanSchemes = (state, action) => {
  localStorage.setItem('loanSchemes', JSON.stringify(action.payload));
  return updateObject(state, { loanSchemes: action.payload });
};

const setLoanScheme = (state, action) => {
  localStorage.setItem('loanScheme', JSON.stringify(action.payload));
  return updateObject(state, { loanScheme: action.payload });
};

const setLoanFee = (state, action) => {
  return updateObject(state, { loanFee: action.payload });
};

const setVerifyPayroll = (state, action) => {
  localStorage.setItem('verifyPayrollData', JSON.stringify(action.data));
  return updateObject(state, { verifyPayrollData: action.data });
};

const setAttemptSubmission = (state, action) => {
  return updateObject(state, { attemptSubmissionKTP: action.payload });
};

const setShouldManualCheck = (state, action) => {
  return updateObject(state, { shouldManualCheck: action.payload });
};

const setNPWP = (state, action) => {
  return updateObject(state, { npwp: action.payload });
};

const setStayPeriod = (state, action) => {
  return updateObject(state, { stayPeriod: action.payload });
};

const setAlternativeAmount = (state, action) => {
  return updateObject(state, { alternativeAmount: action.payload });
};

const setAlternativeTenure = (state, action) => {
  return updateObject(state, { alternativeTenure: action.payload });
};

const setWhitelistReferenceVO = (state, action) => {
  localStorage.setItem('whitelistReferenceVO', JSON.stringify(action.payload));
  return updateObject(state, { whitelistReferenceVO: action.payload });
};

const setRayanetInqRes = (state, action) => {
  localStorage.setItem(
    'rayanetInquiryReferenceVO',
    JSON.stringify(action.payload)
  );
  return updateObject(state, { rayanetInquiryReferenceVO: action.payload });
};

const setAccountInformationInq = (state, action) => {
  localStorage.setItem('accountInformationInq', JSON.stringify(action.payload));
  return updateObject(state, { accountInformationInq: action.payload });
};

const setAccountRayaInformation = (state, action) => {
  localStorage.setItem(
    'accountRayaInformation',
    JSON.stringify(action.payload)
  );
  return updateObject(state, { accountRayaInformation: action.payload });
};

const setNpwpImg = (state, action) => {
  return updateObject(state, { npwpImg: action.value });
};

const setAccountRayaSavingExist = (state, action) => {
  return updateObject(state, { accountRayaSavingExist: action.payload });
};

const setAcctCreatedFromSaving = (state, action) => {
  return updateObject(state, {
    acctCreatedFromSaving: action.acctCreatedFromSaving
  });
};

const setIsExistWhitelist = (state, action) => {
  return updateObject(state, {
    isExistWhitelist: action.payload
  });
};

const setKtpSubmitFormRes = (state, action) => {
  return updateObject(state, {
    ktpSubmitFormRes: action.payload
  });
};

const setAgreeCheckboxKtp = (state, action) => {
  localStorage.setItem('checkboxAgreeKtp', action.payload);
  return updateObject(state, {
    agreeCheckboxKtp: action.payload === 'true' ? true : false
  });
};

const setRespKtpSubmit = (state, action) => {
  localStorage.setItem('respSubmitKtp', JSON.stringify(action.payload));
  return updateObject(state, {
    respSubmitKtp: action.payload
  });
};

const setKasbonInqRes = (state, action) => {
  localStorage.setItem(
    'kasbonInquiryReferenceVO',
    JSON.stringify(action.payload)
  );
  return updateObject(state, { kasbonInquiryReferenceVO: action.payload });
};

const setIsCompanyUpdated = (state, action) => {
  return updateObject(state, {
    isCompanyUpdated: action.payload
  });
};

const setFlagBoxs = (state, action) => {
  return updateObject(state, {
    flagBoxs: action.payload
  });
};

const setBottomSheet = (state, action) => {
  return updateObject(state, {
    bottomSheet: action.payload
  });
};

const setBlockScreen = (state, action) => {
  return updateObject(state, {
    blockScreen: action.payload
  });
};

const setIsMaintenance = (state, action) => {
  return updateObject(state, {
    isMaintenance: action.payload
  });
};
const setIsSameContactOther = (state, action) => {
  return updateObject(state, {
    isSameContactOther: action.payload
  });
};

const setDocRiplay = (state, action) => {
  return updateObject(state, { docRiplay: action.doc });
};

const setIsCustomerConsent = (state, action) => {
  return updateObject(state, { isCustomerConsent: action.isCustomerConsent });
};

const setIsWordingPmi = (state, action) => {
  return updateObject(state, { isWordingPmi: action.isWordingPmi });
};

const setFreezeDateSuccess = (state, action) => {
  localStorage.setItem('freezeDateSuccess', action.freezeDateSuccess);
  return updateObject(state, { freezeDateSuccess: action.freezeDateSuccess });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP_LOADING:
      return loading(state, action);
    case actionTypes.RESUME_APPLICATION:
      return setAppData(state, action);
    case actionTypes.SET_LOAN_PRODUCT_OPTIONS:
      return setLoanProductOptions(state, action);
    case actionTypes.SET_LOAN_PURPOSE_OPTIONS:
      return setLoanPurposeOptions(state, action);
    case actionTypes.SET_LOAN_AMOUNT:
      return setLoanAmount(state, action);
    case actionTypes.SET_TENURE:
      return setTenure(state, action);
    case actionTypes.SET_LOAN_PURPOSE:
      return setLoanPurpose(state, action);
    case actionTypes.SET_PRODUCT_CONFIG:
      return setProductConfig(state, action);
    case actionTypes.SET_BANK_OPTIONS:
      return setBankOptions(state, action);
    case actionTypes.SET_EKTP:
      return setEktp(state, action);
    case actionTypes.SET_EKTP_RAYA:
      return setEktpRaya(state, action);
    case actionTypes.SET_BANK_CODE:
      return setBankCode(state, action);
    case actionTypes.SET_CARD_NUMBER:
      return setCardNumber(state, action);
    case actionTypes.SET_CARD_EXPIRED_DATE:
      return setCardExpDate(state, action);
    case actionTypes.SET_ATM_INQUIRY_RESPONSE:
      return setAtmInqRes(state, action);
    case actionTypes.SET_CUSTOMER_NAME:
      return setCustomerName(state, action);
    case actionTypes.SET_APPLICATION_ID:
      return setApplicationId(state, action);
    case actionTypes.SET_APPLICATION_STATUS:
      return setApplicationStatus(state, action);
    case actionTypes.SET_DISBURSE_LIFETIME:
      return setDisburseLifetime(state, action);
    case actionTypes.SET_SUBTRACTION_DISBURSE_LIFETIME:
      return setSubtractionDisburseLifeTime(state);
    case actionTypes.SET_NEXT_DATE:
      return setNextDate(state, action);
    case actionTypes.SET_RESPONSE_CODE_REJECT:
      return setResponseCodeReject(state, action);
    case actionTypes.SET_APP_INQUIRY_RESPONSE:
      return setAppInqRes(state, action);
    case actionTypes.SET_APPROVAL_DETAILS:
      return setApprovalDetails(state, action);
    case actionTypes.SET_INCOMPLETE_APP:
      return setIncompleteAppDetails(state, action);
    case actionTypes.SET_EDUCATION_OPTIONS:
      return setEducationOptions(state, action);
    case actionTypes.SET_FIELD_OF_WORK_OPTIONS:
      return setFieldOfWorkOptions(state, action);
    case actionTypes.SET_CREDIT_CARD_OPTIONS:
      return setCreditCardOptions(state, action);
    case actionTypes.SET_PROVINCE_OPTIONS:
      return setProvinceOptions(state, action);
    case actionTypes.SET_CITY_OPTIONS:
      return setCityOptions(state, action);
    case actionTypes.SET_SUB_DISTRICT_OPTIONS:
      return setSubDistrictOptions(state, action);
    case actionTypes.SET_VILLAGE_OPTIONS:
      return setVillageOptions(state, action);
    case actionTypes.SET_COMPANY_AGENCY_OPTIONS:
      return setCompanyAgencyOptions(state, action);
    case actionTypes.SET_JOB_TITLE_OPTIONS:
      return setJobTitleOptions(state, action);
    case actionTypes.SET_DAILY_NORMAL_TRANSACTIONS_OPTIONS:
      return setDailyNormalTransactionsOptions(state, action);
    case actionTypes.SET_RESIDENCE_OPTIONS:
      return setResidenceOptions(state, action);
    case actionTypes.SET_TYPE_OF_WORK_OPTIONS:
      return setTypeOfWorkOptions(state, action);
    case actionTypes.SET_FIELD_OF_BUSINESS_OPTIONS:
      return setFieldOfBusinessOptions(state, action);
    case actionTypes.SET_FIELD_OF_SUB_BUSINESS_OPTIONS:
      return setFieldOfSubBusinessOptions(state, action);
    case actionTypes.SET_EMPLOYEE_OPTIONS:
      return setEmployeeOptions(state, action);
    case actionTypes.SET_PURPOSE_OF_OPENING_ACCOUNT_OPTIONS:
      return setPurposeOfOpeningAccountOptions(state, action);
    case actionTypes.SET_SOURCE_OF_INCOME_OPTIONS:
      return setSourceOfIncomeOptions(state, action);
    case actionTypes.SET_COMPANY_OPTIONS:
      return setCompanyOptions(state, action);
    case actionTypes.SET_DEPENDENTS_OPTIONS:
      return setDependentsOptions(state, action);
    case actionTypes.SET_MARRIAGE_OPTIONS:
      return setMariageOptions(state, action);
    case actionTypes.SET_RELATIVES_OPTIONS:
      return setRelativesOptions(state, action);
    case actionTypes.SET_APPLICATION_DETAILS:
      return setApplicationDetails(state, action);
    case actionTypes.SET_DOCUMENT_DETAILS:
      return setDocumentDetails(state, action);
    case actionTypes.SET_RESP_PAYROLL_DETAILS:
      return setRespPayrollDetails(state, action);
    case actionTypes.SET_EKTP_IMG:
      return setEktpImg(state, action);
    case actionTypes.SET_PERSON_INFO:
      return setPersonInfo(state, action);
    case actionTypes.SET_KTP_ADDRESS_DETAILS:
      return setKtpAddressDetails(state, action);
    case actionTypes.SET_OCCUPATION_INFO:
      return setOccupationInfo(state, action);
    case actionTypes.SET_FAMILY_INFO:
      return setFamilyInfo(state, action);
    case actionTypes.CREDIT_SCORE_RESPONSE:
      return setCreditScoreRes(state, action);
    case actionTypes.SET_WHITELIST_ADDRESS:
      return setWhitelistAddress(state, action);
    case actionTypes.SET_SECOND_APPLY:
      return setSecondApply(state, action);
    case actionTypes.SET_COMPANY_NAME:
      return setCompanyName(state, action);
    case actionTypes.SET_COMPANY_ADDRESS:
      return setCompanyAddress(state, action);
    case actionTypes.SET_DEHYDRATE_DATA:
      return setDehydrateData(state);
    case actionTypes.SET_LOAN_SCHEMES:
      return setLoanSchemes(state, action);
    case actionTypes.SET_LOAN_SCHEME:
      return setLoanScheme(state, action);
    case actionTypes.SET_LOAN_FEE:
      return setLoanFee(state, action);
    case actionTypes.SET_VERIFY_PAYROLL:
      return setVerifyPayroll(state, action);
    case actionTypes.SET_ACCOUNT_NUMBER:
      return setAccountNumber(state, action);
    case actionTypes.SET_ATTEMPT_SUBMISSION:
      return setAttemptSubmission(state, action);
    case actionTypes.SHOULD_MANUAL_CHECK:
      return setShouldManualCheck(state, action);
    case actionTypes.SET_NPWP:
      return setNPWP(state, action);
    case actionTypes.SET_STAY_PERIOD:
      return setStayPeriod(state, action);
    case actionTypes.SET_ALTERNATIVE_AMOUNT:
      return setAlternativeAmount(state, action);
    case actionTypes.SET_ALTERNATIVE_TENURE:
      return setAlternativeTenure(state, action);
    case actionTypes.SET_CUST_CATEGORY_OPTIONS:
      return setCustCategory(state, action);
    case actionTypes.SET_SUBCODE_LOANSCHEME:
      return setSubCodeLoanScheme(state, action);
    case actionTypes.SET_RAYANET_INQUIRY:
      return setRayanetInqRes(state, action);
    case actionTypes.SET_ACCOUNT_INFORMATION_APP_INQ:
      return setAccountInformationInq(state, action);
    case actionTypes.SET_ACCOUNT_RAYA_INFORMATION:
      return setAccountRayaInformation(state, action);
    case actionTypes.SET_NPWP_IMAGE:
      return setNpwpImg(state, action);
    case actionTypes.SET_ACCOUNT_RAYA_SAVING_EXIST:
      return setAccountRayaSavingExist(state, action);
    case actionTypes.SET_ACCT_CREATED_FROM_SAVING:
      return setAcctCreatedFromSaving(state, action);
    case actionTypes.SET_IS_EXIST_WHITELIST:
      return setIsExistWhitelist(state, action);
    case actionTypes.SET_KTP_SUBMIT_FORM_RES:
      return setKtpSubmitFormRes(state, action);
    case actionTypes.SET_AGREE_CHECKBOX_KTP:
      return setAgreeCheckboxKtp(state, action);
    case actionTypes.SET_RESP_KTP_SUBMIT:
      return setRespKtpSubmit(state, action);
    case actionTypes.SET_KASBON_INQUIRY:
      return setKasbonInqRes(state, action);
    case actionTypes.SET_IS_COMPANY_UPDATED:
      return setIsCompanyUpdated(state, action);
    case actionTypes.SET_FLAGBOX:
      return setFlagBoxs(state, action);
    case actionTypes.SET_BOTTOM_SHEET:
      return setBottomSheet(state, action);
    case actionTypes.SET_BLOCK_SCREEN:
      return setBlockScreen(state, action);
    case actionTypes.SET_IS_MAINTENANCE:
      return setIsMaintenance(state, action);
    case actionTypes.SET_IS_SAME_CONTACT_OTHER:
      return setIsSameContactOther(state, action);
    case actionTypes.SET_WHITELIST_INQUIRY:
      return setWhitelistReferenceVO(state, action);
    case actionTypes.SET_DOC_RIPLAY:
      return setDocRiplay(state, action);
    case actionTypes.SET_IS_CUSTOMER_CONSENT:
      return setIsCustomerConsent(state, action);
    case actionTypes.SET_IS_WORDING_PMI:
      return setIsWordingPmi(state, action);
    case actionTypes.SET_FREEZE_DATE_SUCCESS:
      return setFreezeDateSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
